import React from "react";

import styles from "./StartPageStyle.module.css";

const StartPage = () => {
  return (
    <div className={styles.container}>
      <span className={styles.title}>Китайський чай на Дао</span>
      <div className={styles.topContainer}>
        <div className={styles.containerItem}>
          <img
            src="./img/shen-puer.jpg"
            alt="shen-puer"
            className={styles.itemImg}
          />
          <div className={styles.textContainer}>
            <span className={styles.topText}>Шен Пуер</span>
            <span className={styles.mainText}>
              Бадьорий ефект та солодкий післясмак з присмаком сухофруктів
            </span>
          </div>
        </div>
        <div className={styles.containerItem}>
          <img
            src="./img/green_tea.jpg"
            alt="green-tea"
            className={styles.itemImg}
          />
          <div className={styles.textContainer}>
            <span className={styles.topText}>Зелений чай</span>
            <span className={styles.mainText}>
              Тонкий аромат з витонченим, солодким та прохолодним смаком
            </span>
          </div>
        </div>
        <div className={styles.containerItem}>
          <img src="./img/ulun.jpg" alt="ulun" className={styles.itemImg} />
          <div className={styles.textContainer}>
            <span className={styles.topText}>Улун</span>
            <span className={styles.mainText}>
              Квітково-фруктовий аромат, нектарний смак та рівний післясмак
            </span>
          </div>
        </div>
        <div className={styles.containerItem}>
          <img
            src="./img/shu-puer.jpg"
            alt="shu-puer"
            className={styles.itemImg}
          />
          <div className={styles.textContainer}>
            <span className={styles.topText}>Шу Пуер</span>
            <span className={styles.mainText}>
              Глибокий букет: від деревяних до шоколадно-горіхових нот
            </span>
          </div>
        </div>
        <div className={styles.containerItem}>
          <img
            src="./img/red-tea.jpeg"
            alt="red-tea"
            className={styles.itemImg}
          />
          <div className={styles.textContainer}>
            <span className={styles.topText}>Червоний чай</span>
            <span className={styles.mainText}>
              Багатогранний аромат і нектарний смак з характерною оксамитовою
              терпкістю
            </span>
          </div>
        </div>
        <div className={styles.containerItem}>
          <img
            src="./img/white_tea.jpg"
            alt="white-tea"
            className={styles.itemImg}
          />
          <div className={styles.textContainer}>
            <span className={styles.topText}>Білий чай</span>
            <span className={styles.mainText}>
              Легкий свіжий смак з фруктовими та пряно-травʼянистими нотами
            </span>
          </div>
        </div>
      </div>
      <div className={styles.hrLine} />
      <div className={styles.contentContainer}>
        <div className={styles.aboutUsContent}>
          <span className={styles.aboutUsHeader}>Про нас</span>
          <span className={styles.textInfo}>
            DAO.UA – інтернет магазин елітного китайського чаю в Україні. Мета
            та просвітницька місія DAO донести смак справжнього елітного
            китайського чаю та сформувати українську, унікальну чайну традицію.
          </span>
          <span className={styles.textTitle}>Чайна культура</span>
          <span className={styles.textInfo}>
            Китайська культура чаю, що формувалася 4000 років і зазнала безліч
            незвичайних метаморфоз. DAO.UA стане Вашим провідником із
            захоплюючого, повного дивовижних відкриттів, світу китайського чаю.
          </span>
          <div className={styles.contentImageOne} />
        </div>
        <div className={styles.aboutUsContent}>
          <span className={styles.textTitle}>
            Китайський чай: особливості та переваги
          </span>
          <span className={styles.textInfo}>
            Китайський елітний чай здатний заспокоїти емоції та почуття,
            наділити свідомість бадьорістю та енергією, очистити організм від
            зайвої ваги. Справжній китайський чай просвітлює глибини свідомості,
            творчо надихає емоції та живить організм енергією Ці.
          </span>
          <span className={styles.textTitle}>Чай та здоров'я</span>
          <span className={styles.textInfo}>
            Чай – ефективне та природне джерело бадьорості, енергії, здоров'я
            ментального спокою та довголіття. У чайному інтернет магазині DAO.UA
            Ви можете купити енергійний або заспокійливий чай для схуднення та
            детоксу.
          </span>
          <span className={styles.textInfoSpecial}>
            Про такий чай китайці кажуть: «пити, щоб проходити повз аптеки».
          </span>
          <span className={styles.textInfo}>
            Чай містить понад 450 хімічних сполук, при цьому багато
            мікроелементів є унікальними і знайдені тільки в чаї (теанін). У
            біохімії всього 21 амінокислота та 18 з них містить у собі чай. Тому
            достатньо кількох чашок чаю на день, щоб заповнити добову норму
            вітамінів та мінералів, необхідних для здоров'я тіла та душі.
          </span>
          <div className={styles.contentImageTwo} />
          <span className={styles.textTitle}>
            Де можна придбати якісний китайський чай в Україні?
          </span>
          <span className={styles.textInfo}>
            Чайна карта DAO включає кращі елітні чаї Китаю, за ексклюзивними
            цінами. У Китаї тисячоліттями вдосконалювали технології створення
            чаю. Це виключно ручна праця, за традиційними династичними
            технологіями, що передаються всередині кланів, і повторити які
            неможливо. У Китаї виробляється понад сім тисяч сортів чаю. Якість
            смак та користь, чаю безпосередньо залежить, дотримання
            технологічних стандартів. Китайці сформулювали чайну класифікацію за
            рівнем ферментації. Ферментація – це біохімічний процес окиснення,
            мікроелементів у чайному листі, під впливом кисню. Саме ферментація
            значною мірою формує смак, аромат та енергетичний ефект чаю.
          </span>
        </div>
        <div className={styles.aboutUsContent}>
          <span className={styles.textTitle}>
            Кращі китайські чаї від інтернет магазину чаю DAO в Україні.
          </span>
          <span className={styles.textTitleCategory}>Зелений чай</span>
          <span className={styles.textInfo}>
            Китай – найбільший у світі експортер зеленого чаю у світі. 500
            сортів зеленого чаю класифікують за технологією обробки: делікатне
            прожарювання, прогрівання на сонці або технологія пропарювання.
          </span>
          <span className={styles.textTitleCategory}>Білий чай</span>
          <span className={styles.textInfo}>
            У Китаї білий чай цінують за дивовижний ніжний смак енергію та
            цілющі властивості. Білий чай м'який, енергійний і дуже корисний
            напій. Лаконічна технологія білого чаю включає лише два етапи:
            збирання весняного листа і висушування чаю в променях тропічного
            сонця.
          </span>
          <span className={styles.textTitleCategory}>Улун</span>
          <span className={styles.textInfo}>
            Улун або бірюзовий чай – унікальне явище чайної культури. Улун має
            винятковий смак, неймовірну енергію характерну для пуерів і зелених
            чаїв.
          </span>
          <span className={styles.textTitleCategory}>Червоний чай</span>
          <span className={styles.textInfo}>
            Китайський червоний чай у європейській традиції – чорний сильно
            ферментований чай. Технологія червоного чаю – завалювання,
            ферментація, просушування та скручування. Найзнаменитіший червоний
            чай – Дянь Хун.
          </span>
          <span className={styles.textTitleCategory}>Пуер</span>
          <span className={styles.textInfo}>
            Пуер – найзнаменитіший чай у Китаї. Пуер має дивовижну енергію,
            сприяє схуднення та емоційно-ментальному балансу. Смак глибокий
            багатогранний, з ароматами лотосу евкаліпту та сандалу.
          </span>
          <span className={styles.textTitleCategory}>Квітковий чай</span>
          <span className={styles.textInfo}>
            У Китаї будь-який вид чаю можна ароматизувати природним ароматом
            квітів. Чайний лист чудово поєднується з квітами лотоса, троянди,
            жасмину, півонії або женьшеню.
          </span>
          <div className={styles.contentImageThree} />
          <span className={styles.textInfo}>
            Чай заспокоює, приводить у баланс, живить життя. Чай може бути
            джерелом глибоких медитативних станів свідомості. Невипадково, мудрі
            буддійські та даоські ченці тисячоліттями використовували пуер та
            зелений чай у медитаціях. Любіть та поважайте себе. Правильно
            заварений чай трансформує чайну дію в витончений процес самопізнання
            себе та світу.
          </span>
        </div>
      </div>
    </div>
  );
};

export default StartPage;
