import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { SaluteProvider } from "./context/SaluteContext";
import { ContentfulProvider } from "./context/ContentfulContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ContentfulProvider>
      <SaluteProvider>
        <App />
      </SaluteProvider>
    </ContentfulProvider>
  </React.StrictMode>
);
