import React from "react";

import styles from "./MainFooterStyle.module.css";

const MainFooter = () => {
  return (
    <div className={styles.container}>
      <div className={styles.infoSocial}>
        <div className={styles.imgLinkSocial}>
          <a
            href="https://www.facebook.com/dao.ua.store/"
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles.imgLinkFacebook} />
          </a>
          <a
            href="https://www.instagram.com/dao.ua.store/"
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles.imgLinkInstagram} />
          </a>
          <a href="https://t.me/dao_ua/" target="_blank" rel="noreferrer">
            <div className={styles.imgLinkTelegram} />
          </a>
        </div>
        <span className={styles.copyrightText}>2023 © DAO.UA</span>
      </div>
      {/* <div className={styles.contacts}>
        <span className={styles.textContacts}>Контакти</span>
        <div className={styles.linksContacts}>
          <a href="tel:0686096969" className={styles.linkTel}>
            +38 (068) 609-69-69,
          </a>
          <a
            href="mailto:info@dao.ua"
            className={styles.linkTel}
            style={{
              marginLeft: "5px",
            }}
          >
            info@dao.ua
          </a>
        </div>
      </div> */}
    </div>
  );
};

export default MainFooter;
