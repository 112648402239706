import React, { createContext, useContext, useState, useEffect } from "react";

const SaluteContext = createContext();

export const useSaluteContext = () => useContext(SaluteContext);

export const SaluteProvider = ({ children }) => {
  const [saluteText, setSaluteText] = useState("");
  const welcomeText = [
    "Доброго ранку, цінитель чаю",
    "Добрий день, цінитель чаю",
    "Доброго вечора, цінитель чаю",
    "Доброї ночі, цінитель чаю",
  ];

  useEffect(() => {
    const getCurrentHour = () => {
      const currentHour = new Date().getHours();

      if (currentHour >= 0 && currentHour < 6) {
        return 3;
      } else if (currentHour >= 6 && currentHour < 12) {
        return 0;
      } else if (currentHour >= 12 && currentHour < 18) {
        return 1;
      } else {
        return 2;
      }
    };

    const index = getCurrentHour();
    setSaluteText(welcomeText[index]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextValue = {
    saluteText,
  };

  return (
    <SaluteContext.Provider value={contextValue}>
      {children}
    </SaluteContext.Provider>
  );
};
