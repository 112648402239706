import { createClient } from "contentful";

const SPACE_ID = "nxwd08xatpf3";
const ACCESS_TOKEN = "9MgoDESeiV_Nmhs7LdmZsJkmnQzHfSJHkmpXw2mQA-0";

const client = createClient({
  space: SPACE_ID,
  accessToken: ACCESS_TOKEN,
});

export const getContentfulData = async (contentType) => {
  try {
    const response = await client.getEntries({
      content_type: contentType,
    });
    return response.items;
  } catch (error) {
    console.error("Error fetching Contentful data:", error);
    return [];
  }
};
