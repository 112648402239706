import React from "react";
import { useSaluteContext } from "../../context/SaluteContext";

import styles from "./MainHeaderStyle.module.css";

const MainHeader = () => {
  const { saluteText } = useSaluteContext();

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <div className={styles.topInfo}>
          <a href="tel:0686096969" className={styles.linkTel}>
            +38 (068) 609-69-69
          </a>
          <span className={styles.text}>Пн-пт з 9-00 до 18-00</span>
        </div>
        <div className={styles.mainInfo}>
          <img src="./img/logo.svg" alt="Dao.ua" className={styles.logo} />
          <span className={styles.mainText}>{saluteText}</span>
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
