import React from "react";

import styles from "./TechnicalWorks.module.css";

const TechnicalWorks = () => {
  return (
    <div className={styles.container}>
      <span className={styles.title}>
        Наразі на сайті проводяться технічні роботи.
      </span>
      <span className={styles.main}>
        Щоб оформити замовлення будь-ласка напишіть нам на{" "}
        <a href="mailto:info@dao.ua" className={styles.linkTel}>
          info@dao.ua
        </a>{" "}
        чи у месенджери{" "}
        <a href="tel:0686096969" className={styles.linkTel}>
          +38 (068) 609-69-69
        </a>
      </span>

      <span className={styles.bottom}>
        Просимо вибачення за тимчасові незручності.
      </span>
    </div>
  );
};

export default TechnicalWorks;
