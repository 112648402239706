import React from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import StartPage from "./components/StartPage/StartPage";
import MainHeader from "./components/MainHeader/MainHeader";
import MainFooter from "./components/MainFooter/MainFooter";
import TechnicalWorks from "./components/TechWorks/TechWorks";

const App = () => {
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <BrowserRouter
        history={createBrowserHistory({
          scrollRestoration: "auto",
        })}
      >
        <MainHeader />
        <Routes>
          <Route path="/" element={<StartPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <MainFooter />
        <TechnicalWorks/>
      </BrowserRouter>
    </div>
  );
};

export default App;
