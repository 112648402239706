import React, { createContext, useContext, useState, useEffect } from "react";
import { getContentfulData } from "../services/contentful/ContentfulService";

const ContentfulContext = createContext();

export const useContentful = () => useContext(ContentfulContext);

export const ContentfulProvider = ({ children }) => {
  const [content, setContent] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getContentfulData("simplePage");
        setContent(data);
      } catch (error) {
        console.error("Error fetching contentful data:", error);
      }
    };

    fetchData();
  }, []);

  const contextValue = {
    content,
  };

  return (
    <ContentfulContext.Provider value={contextValue}>
      {children}
    </ContentfulContext.Provider>
  );
};
